import React from 'react'
import { getImage, StaticImage } from 'gatsby-plugin-image'
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Button, Container, Grid } from '@material-ui/core'
import IconCovid1 from '../../images/icon-covid-1.png'
import IconCovid2 from '../../images/icon-covid-2.png'
import IconCovid3 from '../../images/icon-covid-3.png'
import Line from '../../images/covid-line.png'

export default function CovidInfo() {
  const { t } = useTranslation();

  const { placeholderImage } = useStaticQuery(
    graphql`
          query {
            placeholderImage: file(relativePath: { eq: "background-covid-info.png" }) {
              childImageSharp {
                gatsbyImageData(quality:100,layout:FULL_WIDTH,transformOptions:{fit:COVER}, aspectRatio: 2.05 , placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        `
  )
  const image = getImage(placeholderImage)

  // Use like this:
  const bgImage = convertToBgImage(image)
  return (
    <BackgroundImage
      Tag="section"
      {...bgImage}
    >
      <Container className='content-banner'>
        <Grid container>
          <Grid item xs={4}>
            <Grid container justify='center'>
              <div className='line'>
                <img src={Line} alt='line' />
              </div>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Grid container style={{ height: '100%' }} alignItems='center' className='items-container'>
              <div
                data-sal="slide-left"
                data-sal-duration="2000"
                data-sal-delay="500"
              >
                <img src={IconCovid1} alt='desinfectar' />
                <p>{t('Covid Step 1')}</p>
              </div>
              <br></br>
              <br></br>
              <div
                data-sal="slide-left"
                data-sal-duration="2000"
                data-sal-delay="700"
              >
                <img src={IconCovid2} alt='desinfectar' />
                <p>{t('Covid Step 2')}</p>
              </div>
              <br></br>
              <br></br>
              <div
                data-sal="slide-left"
                data-sal-duration="2000"
                data-sal-delay="900"
              >
                <img src={IconCovid3} alt='desinfectar' />
                <p>{t('Covid Step 3')}</p>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </BackgroundImage>
  )
}
