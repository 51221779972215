import React from 'react' 
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Button, Container } from '@material-ui/core'

export default function CovidBanner() {
    const { t } = useTranslation();

    const { placeholderImage } = useStaticQuery(
        graphql`
          query {
            placeholderImage: file(relativePath: { eq: "background-covid-banner.png" }) {
              childImageSharp {
                gatsbyImageData(quality:100,layout:FULL_WIDTH,transformOptions:{fit:COVER}, aspectRatio: 2.05 , placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        `
    )
    const image = getImage(placeholderImage)

    // Use like this:
    const bgImage = convertToBgImage(image)
    return (
        <BackgroundImage
            Tag="section"
            {...bgImage}
            data-sal="fade"
            data-sal-duration="2000" 
        >
            <Container className='content-covid-banner'>
                <h1><span>{t('Soon Ttile P1')}</span></h1>
                <h1><span>{t('Soon Ttile P2')}</span></h1>
            </Container>
        </BackgroundImage>
    )
}
