import React from 'react'
import MainLayout from "../layouts/MainLayout"
import Seo from "../components/seo"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import Footer from './../components/layouts/Footer';
import CovidBanner from './../components/CovidBanner';
import CovidInfo from './../components/CovidInfo';

export default function Covid19Protocol() {
    const {t} = useTranslation();

    return (
        <MainLayout enableSticky>
            <Seo title={t('Covid')} />
            <CovidBanner/>
            <CovidInfo/>
            <Footer isPrincipal/>
        </MainLayout>
    )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
